import React, { useState } from "react";
import "./style/style.css";
import logo from "../../asset/Images/CustomLogoNoBg.png";
import { useWindowSize } from "../../hooks/useWindowSize";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { Link } from "react-scroll";
const Navbar = ({ closeNav, setCloseNav }) => {
  const [navbar, setnavbar] = useState(false);
  /**
   * width and length of current window
   * @return {object} (width, lenght)
   */
  const size = useWindowSize();

  const changebg = () => {
    if (window.pageYOffset >= 80) {
      setnavbar(true);
    } else {
      setnavbar(false);
    }
  };

  window.addEventListener("scroll", changebg);
  return (
    <div className={navbar ? "ContainerNav active" : "ContainerNav"}>
      <img className="logo" src={logo} alt="Logo" />
      {size.width > 600 ? (
        <ul>
          <Link
            className="navItem"
            to="home"
            smooth={true}
            duration={1500}
            activeClass="active"
            spy={true}
          >
            Home
          </Link>
          <Link
            className="navItem"
            to="about"
            smooth={true}
            duration={1500}
            activeClass="active"
            spy={true}
          >
            About
          </Link>
          <Link
            className="navItem"
            to="contact"
            smooth={true}
            duration={1500}
            activeClass="active"
            spy={true}
          >
            Contact
          </Link>
          <li className="navItem">Looking For A Motor?</li>
        </ul>
      ) : (
        <div onClick={() => setCloseNav(!closeNav)} className="mobileMenuIcon">
          {closeNav ? (
            <FiMenu color="#fff" size="2.5em" />
          ) : (
            <MdClose color="#fff" size="2.5em" />
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
