import React from "react";
import "./Style/style.css";
const Contact = () => {
  return (
    <div className="ContainerContact" id="contact">
      <h1>Contact Us </h1>
      <div className="ContactContainer">
        <div className="AddressCard">
          <h4>Address:</h4>
          <p>Unit 8</p>
          <p>121 Avenue Street</p>
          <p>Glasgow</p>
          <p>G40 3SA</p>
          <a
            href="https://www.google.com/maps/place/Unit+8,+121+Avenue+St,+Glasgow+G40+3SA/@55.8502939,-4.2191321,17z/data=!3m1!4b1!4m5!3m4!1s0x4888414d0691c161:0xeb267cea71ae3d4e!8m2!3d55.8502909!4d-4.2169434"
            target="_blank"
            rel="noreferrer"
          >
            Click here to view on maps
          </a>
        </div>
        <div className="EmailnPhoneCard">
          <h4>Email:</h4>
          <p>rapidautoz@outlook.com</p>
          <h4>Phone:</h4>
          <p>07863 636234</p>
          <p className="CallTime">From 09:00am to 17:00pm</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
