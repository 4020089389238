import React from "react";
import About from "../compoents/HomeComponents/About";
import Contact from "../compoents/HomeComponents/Contact_Us";
import Intro from "../compoents/HomeComponents/Intro";

//Home page
//This section will contain the intro page, about me and contact us page
//all will be connected with 'smooth scroll'
const Home = () => {
  return (
    <div>
      <Intro />
      <About />
      <Contact />
    </div>
  );
};

export default Home;
