import React from "react";
import { Link } from "react-scroll";
import "./Style/style.css";
const SideBar = ({ closeNav, setCloseNav }) => {
  return (
    <div className="SideBarContainer">
      <Link
        className="navItem"
        to="home"
        smooth={true}
        duration={1500}
        activeClass="active"
        spy={true}
        onClick={() => {
          setCloseNav(true);
        }}
      >
        Home
      </Link>
      <Link
        className="navItem"
        to="about"
        smooth={true}
        duration={1500}
        activeClass="active"
        spy={true}
        onClick={() => {
          setCloseNav(true);
        }}
      >
        About
      </Link>
      <Link
        className="navItem"
        to="contact"
        smooth={true}
        duration={1500}
        activeClass="active"
        spy={true}
        onClick={() => {
          setCloseNav(true);
        }}
      >
        Contact
      </Link>
      <a
        href="/"
        className="navItem"
        onClick={() => {
          setCloseNav(true);
        }}
      >
        Looking For A Motor?
      </a>
    </div>
  );
};

export default SideBar;
