import React from "react";
import "./style/styles.css";

const OtherService = () => {
  return (
    <div className="OtherServiceContainer">
      <h1 className="Title">We also offer other service... </h1>
      <div className="Service">
        <div className="OSInfo">
          <div>
            <h2>Sell Vechicals</h2>
            <hr />
            <p>
              Are you looking for a new vehicle? Why not have a look at our
              selection, because you might find something you like.
            </p>
            <a className="ServiceButton" href="/">
              Click Here
            </a>
          </div>
        </div>
        <div className="OSInfoTwo">
          <div>
            <h2>Rent Mopeds and E-Scooters</h2>
            <hr />
            <ul>
              <li>
                We also offer short and long term rental for Mopeds and
                E-scooters.
              </li>
              <li>Prices range form £5.99 per day</li>
              <li>
                This is perfect for a new experience or delivery career
                opportunity.
              </li>
            </ul>
            <a
              className="ServiceButton"
              href="https://www.cityrapidforce.com/"
              target="_blank"
              rel="noreferrer"
            >
              Click Here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherService;
