import React from "react";
import introVideo from "./IntroVideo.mp4";
// import introVideoWebm from "./IntroVideo.webm";
import "./style/style.css";
import { Link } from "react-scroll";
import ReactPlayer from "react-player/file";

//Title intro page
const Intro = () => {
  return (
    <div className="ContainerIntro" id="home">
      {/* muted not working on react -- check back for fix  */}
      {/* <div className="VideoContainer">
        <video autoPlay={true} loop={true} defaultm playsInline controls={false}>
          <source src={introVideo} type="video/mp4" />
          <source src={introVideoWebm} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div> */}
      <div className="VideoContainer">
        <ReactPlayer
          playing={true}
          url={introVideo}
          loop={true}
          muted={true}
          playsinline={true}
          volume={0}
          controls={false}
        />
      </div>
      <div className="IntroContent">
        <h1>WE ARE, WHO YOUR LOOKING FOR</h1>
        <h3>Always happy to help</h3>
        <Link
          className="navItem"
          to="contact"
          smooth={true}
          duration={1500}
          activeClass="active"
          spy={true}
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Intro;
