import React from "react";
import "./style/styles.css";
import diagnostic from "../../../asset/Images/serviceIcons/diagnostic.png";
import service from "../../../asset/Images/serviceIcons/Service.png";
import mot from "../../../asset/Images/serviceIcons/MOT.png";
import welding from "../../../asset/Images/serviceIcons/welding.png";
import customer from "../../../asset/Images/serviceIcons/customer.png";
import OtherService from "./OtherService";
const About = () => {
  return (
    <div id="about">
      <div className="ContainerAbout">
        <h1 className="Title">About Us</h1>
        <p className="ContentAbout">
          We are a friendly garage located in Glasgow, with traditional values
          looking to provide the best service to all our customers. We cater to
          all models, ranging from cars and motorbikes. And these are some of
          the services we offer.
        </p>

        <div className="Service">
          <div className="ServiceIcon">
            <h4>Full Diagnostic Capability</h4>
            <img
              src={diagnostic}
              alt="Icon made by Freepik from www.flaticon.com"
            />
          </div>
          <div className="ServiceIcon">
            <h4>Service and Repair</h4>
            <img
              src={service}
              alt="Icon made by BomSymbols from www.flaticon.com"
            />
          </div>
          <div className="ServiceIcon">
            <h4>MOT Testing for Cars and Motorbikes</h4>
            <img
              src={mot}
              alt="Icon made by 
Eucalyp from www.flaticon.com"
            />
          </div>
          <div className="ServiceIcon">
            <h4>Welding</h4>
            <img
              src={welding}
              alt="Icon made by 
Smashicons from www.flaticon.com"
            />
          </div>
          <div className="ServiceIcon">
            <h4>Customer First</h4>
            <img
              src={customer}
              alt="Icon made by 
Smashicons from www.flaticon.com"
            />
          </div>
        </div>
      </div>
      <div>
        <OtherService />
      </div>
    </div>
  );
};

export default About;
